import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';

import { useQuery } from '@apollo/client';
import { logger } from '@hpx-it/react-app';
import { AutoFixHighOutlined, Close, EditOutlined } from '@mui/icons-material';
import { PlayCircleFilled } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popper,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
// @ts-ignore
import ImageEditor from '@toast-ui/react-image-editor';
import { AttachmentApiContext, TECHNICIAN_QUERY } from 'api';
import { UserContext } from 'contexts';
import { CreateEquipmentContext } from 'contexts';
import { getMediaType } from 'pages/technician-queue-dashboard/component/queue-technician-dashboard/components/TicketDetailsColumn/TicketDetails/TicketDetailsComponents';
import {
  AttachmentUrl,
  MediaType,
} from 'pages/technician-queue-dashboard/types';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { Equipment, TechnicianQuery, TechnicianQueryVariables } from 'types';
import { pushToastMessage } from 'utils';

import { OCRModal } from './OCRModal';

type ScreenshotProps = {
  id: string;
  url: string;
  mediaType: MediaType;
  selectedRA: { id: string };
  setScreenshotURLs: Dispatch<SetStateAction<Record<string, AttachmentUrl>>>;
  pastRA?: boolean;
};
export const Screenshot = ({
  id,
  url,
  mediaType,
  selectedRA,
  setScreenshotURLs,
  pastRA,
}: ScreenshotProps) => {
  const { token } = useContext(UserContext);
  const { setEquipmentToCreate } = useContext(CreateEquipmentContext);
  const { uploadAttachment } = useContext(AttachmentApiContext);
  const theme = useTheme();

  const [dropdownAnchorElement, setDropdownAnchorElement] =
    useState<null | HTMLElement>(null);
  const [mouseOnMenu, setMouseOnMenu] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [confirmCloseDialogOpen, setConfirmCloseDialogOpen] = useState(false);
  const previousAnchor = useRef<HTMLElement | null>(null);
  const handleClose = useCallback(() => {
    if (changesMade) {
      setConfirmCloseDialogOpen(true);
    } else {
      setEditDialogOpen(false);
    }
  }, [changesMade]);
  const [ocrOpen, setOcrOpen] = useState(false);
  const [isImageZoomed, setIsImageZoomed] = useState(false);
  const [attachmentsApiLoading, setAttachmentsApiLoading] = useState(false);

  type handleCreateEquipmentParams = {
    modelNumber?: string;
    serialNumber?: string;
    logo?: string;
  };

  const handleCreateEquipment = ({
    modelNumber,
    serialNumber,
    logo,
  }: handleCreateEquipmentParams) => {
    const createdEquipment: Equipment = {
      name: '',
      type: '',
      brand: logo ?? '',
      modelNumber: modelNumber ?? '',
      serialNumber: serialNumber ?? '',
      errorCode: '',
      equipmentNotes: '',
    };
    setEquipmentToCreate(createdEquipment);

    if (pastRA === false) {
      setOcrOpen(false);
      pushToastMessage({
        content: 'Equipment created and visible in the Equipment Tab',
        variant: 'success',
        autohide: 6000,
      });
    } else {
      pushToastMessage({
        content: 'Equipment can only be created within a video session',
        variant: 'info',
        autohide: 5000,
      });
    }
  };

  const imageRef = useRef<null | any>();
  const currentRef = useRef<null | any>();

  const { data: { technician } = {} } = useQuery<
    TechnicianQuery,
    TechnicianQueryVariables
  >(TECHNICIAN_QUERY, {
    fetchPolicy: 'cache-only',
  });

  const editAndSaveScreenshot = async () => {
    const instance = imageRef.current.getInstance();
    const dataURL = instance.toDataURL();
    const response = await fetch(dataURL);
    const blob = await response.blob();
    const file = new File([blob], 'screenshot.jpg', {
      type: 'image/jpeg',
    });
    const input = {
      attached_to_type: 'remote_assist' as const,
      attached_to: selectedRA.id,
      file,
      user_type: 'technician' as const,
      user_id: technician?.id,
    };

    const uploadEditedScreenshot = async () => {
      setAttachmentsApiLoading(true);
      try {
        const uploadedAttachment = await uploadAttachment(input);
        if (uploadedAttachment) {
          setScreenshotURLs((screenshotURLs) => {
            return {
              ...screenshotURLs,
              [uploadedAttachment.id]: {
                id: uploadedAttachment.id,
                url: URL.createObjectURL(blob),
                mediaType: getMediaType(uploadedAttachment.file_extension),
                extension: uploadedAttachment.file_extension,
              },
            };
          });
          pushToastMessage({
            content: 'Edited screenshot saved',
            variant: 'success',
            autohide: 5000,
          });
        }
        setEditDialogOpen(false);
      } catch (error) {
        logger.error('Failed to save edited screenshot', { message: error });
        pushToastMessage({
          content: 'Unable to save edited screenshot',
          variant: 'error',
          action: {
            text: 'retry',
            function: uploadEditedScreenshot,
          },
        });
      } finally {
        setAttachmentsApiLoading(false);
      }
    };

    uploadEditedScreenshot();
  };

  return (
    <>
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: theme.palette.common.black,
          '>div': {
            display: 'none',
          },
          ':hover': {
            cursor: 'pointer',
            '>img': {
              opacity: '0.7',
            },
            '>div': {
              display: 'flex',
            },
          },
        }}
      >
        {mediaType === 'video' && (
          <div
            style={{
              position: 'relative',
              display: 'contents',
            }}
          >
            <video
              ref={currentRef}
              onMouseEnter={(event) => {
                setDropdownAnchorElement(event.currentTarget);
              }}
              onMouseLeave={(event) => {
                previousAnchor.current = dropdownAnchorElement;
                setDropdownAnchorElement(null);
              }}
              onClick={() => {
                setIsImageZoomed(true);
              }}
              width="auto"
              height={150}
              src={url}
              controls={false}
              style={{
                maxHeight: '100%',
                maxWidth: '100%',
                cursor: 'zoom-in',
              }}
            />
            <PlayCircleFilled
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '3rem',
                color: 'black',
                pointerEvents: 'none',
                zIndex: 1,
              }}
            />
          </div>
        )}
        {mediaType === 'image' && (
          <img
            ref={currentRef}
            onMouseEnter={(event) => {
              setDropdownAnchorElement(event.currentTarget);
            }}
            onMouseLeave={() => {
              previousAnchor.current = dropdownAnchorElement;
              setDropdownAnchorElement(null);
            }}
            onClick={() => {
              setIsImageZoomed(true);
            }}
            style={{ cursor: 'zoom-in' }}
            width="auto"
            height={150}
            src={url}
            alt="screenshot"
          />
        )}
        <Dialog
          open={isImageZoomed}
          onClose={() => {
            setIsImageZoomed(false);
          }}
          maxWidth="md"
          fullWidth
        >
          <IconButton
            onClick={() => {
              setIsImageZoomed(false);
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
          {mediaType === 'video' ? (
            <video
              src={url}
              controls
              style={{
                maxWidth: '100%',
                maxHeight: '90vh',
                objectFit: 'contain',
                display: 'block',
                margin: 'auto',
              }}
            />
          ) : (
            <img
              src={url}
              alt="screenshot"
              style={{
                maxWidth: '100%',
                maxHeight: '90vh',
                objectFit: 'contain',
                display: 'block',
                margin: 'auto',
              }}
            />
          )}
        </Dialog>
        {mediaType === 'image' && (
          <Popper
            style={{ zIndex: 200 }}
            onMouseEnter={() => {
              setMouseOnMenu(true);
            }}
            onMouseLeave={() => {
              setMouseOnMenu(false);
            }}
            anchorEl={
              !!dropdownAnchorElement
                ? dropdownAnchorElement
                : previousAnchor.current
            }
            open={!!dropdownAnchorElement || mouseOnMenu}
            placement="right"
            modifiers={[
              {
                name: 'arrow',
                enabled: true,
              },
            ]}
          >
            <MenuList
              sx={{
                backgroundColor: theme.palette.grey[100],
                borderRadius: 1,
              }}
            >
              <MenuItem
                onClick={() => {
                  setEditDialogOpen(true);
                  setDropdownAnchorElement(null);
                }}
                sx={{
                  ':hover': {
                    backgroundColor: theme.palette.grey[200],
                  },
                }}
              >
                <ListItemIcon>
                  <EditOutlined fontSize="small" />
                </ListItemIcon>
                <ListItemText>Markup Image</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOcrOpen(true);
                  setDropdownAnchorElement(null);
                }}
                sx={{
                  ':hover': {
                    backgroundColor: theme.palette.grey[200],
                  },
                }}
              >
                <ListItemIcon>
                  <AutoFixHighOutlined fontSize="small" />
                </ListItemIcon>
                <ListItemText> Extract Data </ListItemText>
              </MenuItem>
            </MenuList>
          </Popper>
        )}
      </Box>
      <Dialog
        open={editDialogOpen}
        onClose={handleClose}
        disableEnforceFocus={true}
        maxWidth={false}
      >
        <Box
          sx={{
            position: 'relative',
            width: 'auto',
            bgcolor: '#151515',
            justifyContent: 'center',
          }}
        >
          <ImageEditor
            key={'img'}
            ref={imageRef}
            includeUI={{
              loadImage: {
                path: url,
                name: 'SampleImage',
              },
              theme: {
                'header.display': 'none',
              },
              menu: ['crop', 'rotate', 'draw', 'shape', 'text'],
              uiSize: {
                width: '1000px',
                height: '700px',
              },
              menuBarPosition: 'bottom',
            }}
            cssMaxHeight={400}
            cssMaxWidth={600}
            selectionStyle={{
              cornerSize: 20,
              rotatingPointOffset: 70,
            }}
            onUndoStackChanged={(length: number) => {
              setChangesMade(length !== 0);
            }}
          />
          <IconButton
            onClick={handleClose}
            sx={{
              color: '#8a8a8a',
              bgcolor: 'rgba(255,255,255,0.06)',
              ':hover': {
                color: theme.palette.common.white,
                bgcolor: 'rgba(255,255,255,0.06)',
              },
              position: 'absolute',
              margin: '8px',
              top: '0',
              left: '0',
              zIndex: 5,
            }}
          >
            <Tooltip title="Close">
              <Close color="inherit" />
            </Tooltip>
          </IconButton>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => editAndSaveScreenshot()}
            disabled={attachmentsApiLoading || !changesMade}
            sx={{
              position: 'absolute',
              margin: '15px',
              bottom: '0',
              right: '0',
              zIndex: 5,
            }}
          >
            SAVE
          </Button>
        </Box>
      </Dialog>
      <Dialog
        open={confirmCloseDialogOpen}
        onClose={() => setConfirmCloseDialogOpen(false)}
      >
        <Box p={2}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography variant="h6">Closing Screenshot Edit</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                You are closing Screenshot Edit with changes made to the
                screenshot. Are you sure you wish to close without saving?
              </Typography>
            </Grid>
            <Grid item container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  color="secondary"
                  onClick={() => setConfirmCloseDialogOpen(false)}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    setConfirmCloseDialogOpen(false);
                    setEditDialogOpen(false);
                  }}
                >
                  Close without saving
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <OCRModal
        token={token ?? ''}
        url={url}
        attachmentId={id}
        isOpen={ocrOpen}
        setIsOpen={setOcrOpen}
        handleCreateEquipment={handleCreateEquipment}
      />
    </>
  );
};
