import { Ticket } from '@hpx-it/queue-client';
import { useCallback, useEffect, useMemo, useState } from 'react';

class TicketEvent extends CustomEvent<Ticket> {}

function getTicketEventName(ticketId: string) {
  return `ticket-updated:${ticketId}`;
}

function useTicketBase<T extends Ticket | undefined>(
  initialTicket: T,
): [T, (ticket: T) => void] {
  const [ticket, _setTicket] = useState<T>(initialTicket);

  const ticketId = useMemo(() => ticket?.getTicketId(), [ticket]);
  const ticketTimestampedId = useMemo(
    () => ticket?.getTimestampedId(),
    [ticket],
  );

  const ticketEventName = useMemo(
    () => (ticketId ? getTicketEventName(ticketId) : undefined),
    [ticketId],
  );

  useEffect(() => {
    if (ticketEventName) {
      function listenAndSetTicket(event: Event) {
        if (event instanceof TicketEvent) {
          _setTicket(event.detail.clone() as T);
        }
      }

      window.addEventListener(ticketEventName, listenAndSetTicket);

      return () =>
        window.removeEventListener(ticketEventName, listenAndSetTicket);
    }
  }, [ticketEventName]);

  const sendEventForTicket = useCallback(
    (newTicket: T) => {
      // setting to new ticket
      if (ticketId !== newTicket?.getTicketId()) {
        _setTicket(newTicket?.clone() as T);
        // updating existing ticket
      } else if (
        newTicket &&
        newTicket?.getTimestampedId() !== ticketTimestampedId
      ) {
        window.dispatchEvent(
          new TicketEvent(getTicketEventName(newTicket.getTicketId()), {
            detail: newTicket,
          }),
        );
      }
    },
    [ticketId, ticketTimestampedId],
  );

  return [ticket, sendEventForTicket];
}

export const useOptionalTicket = (initialTicket?: Ticket) => {
  return useTicketBase(initialTicket);
};

export const useTicket = (initialTicket: Ticket) => {
  return useTicketBase(initialTicket);
};
