import { Box, CircularProgress, Theme, ThemeProvider } from '@mui/material';
import { DeveloperApiProvider } from 'api';
import { TradeApiContext } from 'api';
import {
  LeftNavigation,
  ToastMessageContainer,
  TopNavigation,
} from 'components';
import { ConfigureModal } from 'components/configure-modal';
import {
  ClientContext,
  ClientProvider,
  DevicesContext,
  UserContext,
} from 'contexts';
import { TechnicianContext } from 'contexts/TechnicianContext';
import { ViewsContext, ViewsProvider } from 'contexts/ViewsContext';
import { Suspense, useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  LEFT_NAV_WIDTH,
  LEFT_NAV_WIDTH_EXPANDED,
  TOP_NAV_HEIGHT,
  generateTheme,
} from 'utils';
import * as constants from 'utils/constants';

import { Loading } from './loading';

const VIEW_HEIGHT = `calc(100vh - ${TOP_NAV_HEIGHT})`;

function ViewRoutes() {
  const { views } = useContext(ViewsContext);

  return (
    <Routes>
      {views.map((view) => (
        <Route path={view.path} element={<view.component />} />
      ))}
    </Routes>
  );
}

function ViewRoutesContainer() {
  return (
    <Box height="100%" width="100%" id="view-container">
      <ViewRoutes />
    </Box>
  );
}

function ViewTemplate() {
  const { isUserAuthenticated } = useContext(UserContext);
  const { showConfigure, setShowConfigure } = useContext(DevicesContext);
  const [isLHSMenuOpen, setIsLHSMenuOpen] = useState(false);
  const { technician } = useContext(TechnicianContext);
  const { showNav } = useContext(ViewsContext);
  const { isLoadingBrand } = useContext(ClientContext);
  const { getTrades } = useContext(TradeApiContext);
  const [constantsLoaded, setConstantsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (isUserAuthenticated) {
      // load constants
      (async () => {
        await constants.init(getTrades);
        setConstantsLoaded(true);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthenticated]);

  if (isLoadingBrand || !constantsLoaded) {
    return (
      <Loading
        loading={isLoadingBrand || !constantsLoaded}
        technicianError={undefined}
      />
    );
  }

  return (
    <>
      {showNav && (
        <LeftNavigation
          isLHSMenuOpen={isLHSMenuOpen}
          setIsLHSMenuOpen={setIsLHSMenuOpen}
        />
      )}
      <Box
        style={{
          marginLeft: showNav
            ? isLHSMenuOpen
              ? LEFT_NAV_WIDTH_EXPANDED
              : LEFT_NAV_WIDTH
            : 0,
          transition: 'all 0.75s',
          height: '100vh',
        }}
      >
        {showNav && <TopNavigation technician={technician} />}
        <ToastMessageContainer />
        <Suspense
          fallback={
            <Box
              width="100%"
              height={VIEW_HEIGHT}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress size={100} />
            </Box>
          }
        >
          <Box height={showNav ? VIEW_HEIGHT : '100vh'} width="100%">
            <ViewRoutesContainer />
          </Box>
        </Suspense>
        {showConfigure && (
          <ConfigureModal onClose={() => setShowConfigure(false)} />
        )}
      </Box>
    </>
  );
}

export function FieldguideRoutes() {
  const { isUserAuthenticated, userLoading } = useContext(UserContext);
  const { error } = useContext(TechnicianContext);
  const [theme, setTheme] = useState<Theme>(generateTheme());

  if (!isUserAuthenticated || userLoading || error) {
    return (
      <Loading
        loading={userLoading || !isUserAuthenticated}
        technicianError={error}
      />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <DeveloperApiProvider>
        <ClientProvider setTheme={setTheme}>
          <ViewsProvider>
            <ViewTemplate />
          </ViewsProvider>
        </ClientProvider>
      </DeveloperApiProvider>
    </ThemeProvider>
  );
}
