import { Ticket } from '@hpx-it/queue-client';
import { TicketLockOptions } from '@hpx-it/queue-client/src/types';
import { QueueApiContext, QueueFilter } from 'api';
import { TechnicianContext, UserContext } from 'contexts';
import { useInterval } from 'hooks';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { pushToastMessage } from 'utils';

import { AttachmentUrl } from '../types';

const POLLING_INTERVAL_SECONDS = 10;

type QueueDashboardContextProps = {
  queue: Ticket[];
  propertyMap: Map<string, number>;
  setQueue: Dispatch<SetStateAction<Ticket[]>>;
  queueFilter: QueueFilter;
  setQueueFilter: (filter: QueueFilter) => void;
  claimedTickets: Ticket[];
  setClaimedTickets: Dispatch<SetStateAction<Ticket[]>>;
  queueLoading: boolean;
  claimedTicketsLoading: boolean;
  selectedImage: AttachmentUrl | undefined;
  setSelectedImage: (attachmentUrl: AttachmentUrl | undefined) => void;
  userId: string;
  userName: string;
  getClaimedByLock: (prefix: string) => TicketLockOptions;
};

export type QueueDashboardProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: QueueDashboardContextProps = {
  queue: [],
  propertyMap: new Map(),
  setQueue: () => {},
  queueFilter: { name: 'none' },
  setQueueFilter: () => {},
  claimedTickets: [],
  setClaimedTickets: () => {},
  queueLoading: false,
  claimedTicketsLoading: false,
  selectedImage: undefined,
  setSelectedImage: () => {},
  userId: '',
  userName: '',
  getClaimedByLock: () => ({ enabled: false }),
};

function getPropertyMap(tickets: Ticket[]): Map<string, number> {
  const locationMap = new Map<string, number>();

  tickets.forEach((ticket) => {
    const location = ticket.getLocation();
    // Use the nullish coalescing operator to get the current count or 0, then increment it
    locationMap.set(location, (locationMap.get(location) ?? 0) + 1);
  });

  return locationMap;
}

export const QueueDashboardContext =
  createContext<QueueDashboardContextProps>(DEFAULT_CONTEXT);

export const QueueDashboardProvider = ({
  children,
}: QueueDashboardProviderProps) => {
  const { technician } = useContext(TechnicianContext);
  const { userId: fronteggUserId, userName } = useContext(UserContext);
  const { getQueue, getPinnedTickets } = useContext(QueueApiContext);
  const [queue, setQueue] = useState<Ticket[]>([]);
  const [propertyMap, setPropertyMap] = useState<Map<string, number>>(
    new Map(),
  );
  const [claimedTickets, setClaimedTickets] = useState<Ticket[]>([]);
  const [queueFilter, setQueueFilter] = useState<QueueFilter>({ name: 'none' });
  const queueFilterRef = useRef(queueFilter);

  useEffect(() => {
    queueFilterRef.current = queueFilter;
  }, [queueFilter]);

  const [queueLoading, setQueueLoading] = useState<boolean>(false);
  const [claimedTicketsLoading, setClaimedTicketsLoading] =
    useState<boolean>(false);

  const [selectedImage, setSelectedImage] = useState<AttachmentUrl | undefined>(
    undefined,
  );

  const queueUserId = useMemo(
    () => technician?.id ?? fronteggUserId,
    [technician?.id, fronteggUserId],
  );

  const fetchTickets = useCallback(
    async (filter: QueueFilter) => {
      const fetchedQueue = await getQueue(filter);
      if (fetchedQueue && queueFilterRef.current === filter) {
        setQueue(fetchedQueue);
        setPropertyMap(getPropertyMap(fetchedQueue));
      }
    },
    [getQueue],
  );

  const fetchTicketsWithLoading = useCallback(
    async (filter: QueueFilter) => {
      setQueueLoading(true);
      try {
        await fetchTickets(filter);
      } finally {
        setQueueLoading(false);
      }
    },
    [fetchTickets],
  );

  const fetchClaimedTickets = useCallback(async () => {
    const fetchedQueue = await getPinnedTickets(queueUserId);
    if (fetchedQueue) {
      setClaimedTickets(fetchedQueue);
    }
  }, [getPinnedTickets, queueUserId]);

  const fetchClaimedTicketsWithLoading = useCallback(async () => {
    setClaimedTicketsLoading(true);
    try {
      await fetchClaimedTickets();
    } finally {
      setClaimedTicketsLoading(false);
    }
  }, [fetchClaimedTickets]);

  // Get both queues without loading
  const fetchAndSetQueue = useCallback(async () => {
    fetchTickets(queueFilter);
    fetchClaimedTickets();
  }, [fetchClaimedTickets, fetchTickets, queueFilter]);

  useEffect(() => {
    fetchClaimedTicketsWithLoading();
  }, [fetchClaimedTicketsWithLoading]);

  useEffect(() => {
    fetchTicketsWithLoading(queueFilter);
  }, [fetchTicketsWithLoading, queueFilter]);

  useInterval(fetchAndSetQueue, POLLING_INTERVAL_SECONDS * 1000);

  const getClaimedByLock = useCallback(
    (messagePrefix: string): TicketLockOptions => ({
      enabled: true,
      onLock: async (ticket) => {
        const updatedTicket = await ticket.refetch();
        const claimedBy = updatedTicket.getClaimedByName();
        pushToastMessage({
          content: `${messagePrefix}: ${ticket.getUserViewableId()} is now ${
            claimedBy ? `claimed by ${claimedBy}` : 'unclaimed'
          }`,
          variant: 'warning',
          autohide: 5000,
        });
        return updatedTicket;
      },
    }),
    [],
  );

  return (
    <QueueDashboardContext.Provider
      value={{
        queue,
        propertyMap,
        setQueue,
        queueFilter,
        setQueueFilter,
        claimedTickets,
        setClaimedTickets,
        queueLoading,
        claimedTicketsLoading,
        selectedImage,
        setSelectedImage,
        userId: queueUserId,
        userName: technician?.name ?? userName,
        getClaimedByLock,
      }}
    >
      {children}
    </QueueDashboardContext.Provider>
  );
};
